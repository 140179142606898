import { h } from 'preact'
import { APP, VIEW } from '../state'
import { Color } from './color'

export const setTool = (tool) => {
  APP.tool = tool
  VIEW.render()
}

export const Toolbar = () => {
  return <div class='w-40 bg-light bord-dark-r relative'>
    {
      ['pencil', 'eraser', 'line', 'circle', 'square', 'fill', 'eye-dropper', 'move'].map(tool => 
        <button
          onClick={() => { setTool(tool) }}
          class="fl fl-center m-0 p-0 w-40 h-40 bord-dark-r"
          style={`${APP.tool === tool ? 'background: rgba(52, 152, 219, 255);' : ''}`}>
          <img src={`img/${tool}.svg`} />
        </button>
      )
    }
    <button onClick={() => { VIEW.color.open = !VIEW.color.open; VIEW.render(); }} class='w-40 h-40 relative show-at-850'>
      <div class='w-full no-ptr' style={{ borderRadius: '4px', height: '30px', background: `rgb(${APP.color.rgb[0]},${APP.color.rgb[1]},${APP.color.rgb[2]})` }}></div>
    </button>
    {VIEW.color.open && <div class='bg-dark' style={{ position: 'absolute', top:'60px', left: '100%', zIndex: '100', width: '250px' }}>
        <Color />
      </div>}
  </div>
}

