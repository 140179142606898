import { RGBtoHSL } from './utils'

export const ENV = window.location.href.includes('localhost:4000') | window.location.href.includes('0.0.0.0:3000/') ? 'DEV' : 'PROD'
export const APP = {}
export const VIEW = { render: undefined }
export const canvases = ['canvasSelection', 'canvasPreview', 'canvasTemp', 'canvasFinal', 'canvasView']

const initAppDefault = (w, h) => {
  APP.width = w
  APP.height = h
  APP.tool = 'pencil'

  APP.frameActive = 0
  APP.layerActive = 0
  APP.frameCount = 1 
  APP.layerCount = 1  
  APP.layers = [
    {
      name: 'Layer 1',
      hidden: false,
      frames: [new ImageData(w, h)]
    }
  ]
  
  APP.color = {}
  APP.color.rgb = [100, 188, 156, 255]
  APP.color.hsl = RGBtoHSL(APP.color.rgb)

  APP.palette = [
    [26, 188, 156, 255],
    [46, 204, 113, 255],
    [52, 152, 219, 255],
    [155, 89, 182, 255],
    [52, 73, 94, 255],
    [22, 160, 133, 255],
    [39, 174, 96, 255],
    [41, 128, 185, 255],
    [142, 68, 173, 255],
    [44, 62, 80, 255],
    [241, 196, 15, 255],
    [230, 126, 34, 255],
    [231, 76, 60, 255],
    [236, 240, 241, 255],
    [149, 165, 166, 255],
    [243, 156, 18, 255],
    [211, 84, 0, 255],
    [192, 57, 43, 255],
    [189, 195, 199, 255],
    [127, 140, 141, 255]
  ]
  
  Object.seal(APP)
}

const centerCanvas = () => {
  const canvasWidth = APP.width;
  const canvasHeight = APP.height;

  // Get workspace dimensions
  const { clientWidth: workspaceWidth, clientHeight: workspaceHeight } = document.querySelector('#workspace');

  // Define fixed padding
  const padding = 20; // Fixed value

  // Calculate available space
  const availableWidth = workspaceWidth - padding * 2;
  const availableHeight = workspaceHeight - padding * 2;

  // Calculate scale factor to fit canvas within the available space
  const scaleX = availableWidth / canvasWidth;
  const scaleY = availableHeight / canvasHeight;

  // Choose the smaller scaling factor to fit canvas within bounds
  const newScale = Math.min(scaleX, scaleY);

  // Recalculate available width/height based on newScale for centering
  const scaledCanvasWidth = canvasWidth * newScale;
  const scaledCanvasHeight = canvasHeight * newScale;

  // Center the canvas within the workspace
  const newTranslateX = (workspaceWidth - scaledCanvasWidth) / 2;
  const newTranslateY = (workspaceHeight - scaledCanvasHeight) / 2;

  // Apply scale and translation to the workspace
  VIEW.workspace.scale = newScale;
  VIEW.workspace.translate.x = newTranslateX;
  VIEW.workspace.translate.y = newTranslateY;

  // Re-render the workspace
  VIEW.render();
}

export const initCanvases = () => {
  canvases.forEach(canvas => {
    VIEW[canvas].dom = canvas === 'canvasView' ? document.querySelector('#canvas-view') : document.createElement('canvas')
    VIEW[canvas].dom.width = APP.width
    VIEW[canvas].dom.height = APP.height
    VIEW[canvas].ctx = VIEW[canvas].dom.getContext('2d')
    VIEW[canvas].imgData = VIEW[canvas].ctx.getImageData(0, 0, APP.width, APP.height)
  })

  VIEW.canvasTimeline = document.querySelector('#timeline-canvas')
  VIEW.canvasTimelineTemp = document.createElement('canvas')

  centerCanvas()

  setTimeout(() => {
    const canvasBackground = document.querySelector('#canvas-background');
    canvasBackground.width = APP.width;
    canvasBackground.height = APP.height;
    
    const ctxBackground = canvasBackground.getContext('2d');
  
    // Draw transparency grid pattern
    const gridSize = 1; // Size of each grid square
    for (let y = 0; y < canvasBackground.height; y += gridSize) {
      for (let x = 0; x < canvasBackground.width; x += gridSize) {
        ctxBackground.fillStyle = (x / gridSize + y / gridSize) % 2 === 0 ? '#cccccc' : '#ffffff'; // Alternating colors
        ctxBackground.fillRect(x, y, gridSize, gridSize);
      }
    }
  }, 0)
}

const initViewDefault = (preventOnMount) => {
  VIEW.activeInput = {
    id: '',
    val: ''
  }

  VIEW.window = {
    request: '',
    mouseDown: false,
    startX: 0,
    startY: 0,
    prevX: 0,
    prevY: 0,
    currX: 0,
    currY: 0
  }

  VIEW.file = { open: false }
  VIEW.newCanvas = { open: false, w: 32, h: 32 }
  VIEW.downloadCanvas = { open: false, size: 1, type: 'gif' }
  VIEW.color = { open: false }
  VIEW.gifLibraryLoaded = false
  VIEW.exporting = false

  VIEW.brushSize = 0

  VIEW.timerID = undefined
  VIEW.isPlaying = false
  VIEW.onionSkinning = false

  VIEW.undo = []
  VIEW.undoPos = -1
  VIEW.currUndoRef = {}
  
  VIEW.canvasTimeline = undefined
  VIEW.canvasTimelineTemp = undefined

  VIEW.fps = 8

  VIEW.workspace = {
    scale: 1,
    translate: { x: 0, y: 0 },
    rotate: 0,
    transformDelta: 0,
    touchAction: '',
    touchCount: 0,
    currentTouchDistance: 0,
    initialTouchDistance: 0,
    initialTouchAngle: 0,
    initialTouchMidpoint: { x: 0, y: 0 },
    previousScale: 0,
    lastBrushPosition: null,
    lastTimestamp: null,
    gesture: ''
  }

  VIEW.debug = ''

  // need to reset these on new project
  canvases.forEach(canvas => {
    VIEW[canvas] = {
      dom: undefined,
      ctx: undefined,
      imgData: undefined
    }
  })

  if (preventOnMount !== true) {
    initCanvases()
  }

  Object.seal(VIEW)
}

export const newData = (w, h, preventOnMount) => {
  initAppDefault(w, h)
  initViewDefault(preventOnMount)
}